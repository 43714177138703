import React from 'react';

const conf = () => {
  const l = window.location.hostname === 'localhost';
  return {
    url: l ? `http://localhost?key=${API_KEY}` : `https://api.dalyham.com?key=${API_KEY}`,
  };
}

let L_MAP = null;
let API_KEY = '';

const getCallResults = async () => {
  const url = conf().url;
  const resultRaw = await fetch(url);
  const result = await resultRaw.json();
  return result.data;
}

const Wrapper = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: props.z,
        top: 0,
        left: 0,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}

const LMap = (props) => {
  const { ctx, ctxValue } = props;

  React.useEffect(() => {
    L_MAP = L.map('map').setView([0, 0], 3);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy;',
        noWrap: true,
        bounds: [
          [-90, -180],
          [90, 180],
        ],
    }).addTo(L_MAP);

    const greenIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    const redIcon = new L.Icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    for (const r of ctxValue.results) {
      const marker = L
        .marker([r.lat, r.lon], {
          icon: r.confirmed ? greenIcon : redIcon,
        })
        .addTo(L_MAP)
        .bindPopup(`
          <span class="callsign">${r.callsign}</span>
          <br>
          <span class="data">${r.date}</span>
          <br><br>
          <span class="data">${r.country} ${r.county ? `(${r.county})` : ''}</span>
          <br>
          <span class="data">${r.distance}km (${(r.distance * 0.621371).toFixed(2)}m)</span>
          <br><br>
          <span class="data">Band: ${r.band} ${r.band === r.band_rx ? '' : `(${r.band_rx})`}</span>
          <br>
          <span class="data">Freq: ${r.freq} ${r.freq === r.freq_rx ? '' : `(${r.freq_rx})`}</span>
        `.trim());
      // markers.push(marker);
    }
  }, []);

  return (
    <Wrapper
      z={10}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div
        id="map"
        style={{
          height: '100%',
          width: '100%',
        }}
      >
      </div>
    </Wrapper>
  );
}

const Loading = () => {
  return (
    <Wrapper
      z={1000}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ccc',
        height: '100%',
        width: '100%',
      }}
    >
      <h2 style={{ textAlign: 'center', width: '100%' }}>Please wait...</h2>
    </Wrapper>
  )
}

const App = (props) => {
  const [ctxValue, ctx] = React.useState({});
  const [apiKeyValue, setApiKeyValue] = React.useState('');

  const submitApiKey = async (v) => {
    localStorage.setItem('QRZ_API_KEY', v);
    ctx({ loading: true });
    API_KEY = v;
    const results = await getCallResults();
    ctx({ loading: false, results });
  }

  React.useEffect(() => {
    const existing = localStorage.getItem('QRZ_API_KEY') || '';
    if (!existing) return;
    setApiKeyValue(existing);
    submitApiKey(existing);
  }, []);

  if (!ctxValue.results || ctxValue.results.length === 0) {
    return (
      <>
        {ctxValue.loading && <Loading />}
        <Wrapper
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            backgroundColor: '#ccc',
          }}
        >
          <h2 style={{ textAlign: 'center', width: '100%' }}>
            Please enter your qrz.com API key below:
          </h2>
          <input
            value={apiKeyValue}
            onChange={(e) => setApiKeyValue(e.target.value)}
            style={{
              border: 0,
              outline: 0,
              margin: 0,
              width: '90vw',
              textAlign: 'center',
              padding: '0.5rem',
              font: 'inherit',
            }}
          />
          <button
            onClick={() => submitApiKey(apiKeyValue)}
            style={{
              border: 0,
              outline: 0,
              margin: 0,
              textAlign: 'center',
              padding: '1rem',
              font: 'inherit',
              backgroundColor: 'white',
              marginTop: '2rem',
            }}
          >
            Submit
          </button>
          {!!ctxValue.results && ctxValue.results.length === 0 && (
            <h2 style={{ marginTop: '1rem', textAlign: 'center', width: '100%' }}>
              No results found!
            </h2>
          )}
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <Wrapper
        z={1000}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
        }}
      >
        <button
          style={{
            pointerEvents: 'auto',
            border: 0,
            outline: 0,
            margin: 0,
            textAlign: 'center',
            padding: '0.5rem',
            font: 'inherit',
            backgroundColor: 'white',
            marginTop: '2rem',
          }}
          onClick={() => {
            localStorage.setItem('QRZ_API_KEY', '');
            window.location.href = '/';
          }}
        >
          Reset
        </button>
      </Wrapper>
      <LMap
        {...{ ctx, ctxValue }}
      />
    </>
  );
}

export default App;
